import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import * as ProductAPI from "../../api/v1/product";
import * as OrderProductAPI from "../../api/v1/orderProduct";
import { getAccountId } from "../order/selectors";
import { UPConfig } from "./types";

export const fetchProductSearch = createAsyncThunk(
  "universalProducts/fetchProductSearch",
  async (arg, { getState }) => {
    const state: any = getState();
    const accountId = getAccountId(state);
    const response = await ProductAPI.universalSearch({
      account_id: accountId,
      guided_sales: 1,
      // category_id: accountSettings.category_id_universal,
    });
    return response;
  }
);

export const fetchProductDocuments = createAsyncThunk(
  "universalProducts/fetchProductDocuments",
  async (productId: any, { getState }) => {
    const response = await ProductAPI.listDocuments(productId);
    return response;
  }
);

export const addProduct = createAction<string>("universalProducts/addProduct");

export const removeProduct = createAction<string>(
  "universalProducts/removeProduct"
);

export const selectConfig = createAction<number | boolean>(
  "universalProducts/selectConfig"
);

export const removeConfig = createAction<number>(
  "universalProducts/removeConfig"
);

export const clearConfig = createAction("universalProducts/clearConfig");

export const addUserDocument = createAction<any>(
  "universalProducts/addUserDocument"
);

export const removeUserDocument = createAction<number>(
  "universalProducts/removeUserDocument"
);

export const setUserDocumentSendPref = createAction(
  "universalProducts/setUserDocumentSendPref",
  (index, pref) => ({
    payload: { index, pref },
  })
);

export const setConfigProperty = createAction(
  "universalProducts/setConfigProperty",
  (property: keyof UPConfig, value) => ({
    payload: { property, value },
  })
);

export const orderProduct = createAsyncThunk(
  "universalProducts/orderProduct",
  async (
    { configIndex, config, isUpdate, orderProductId, orderParams }: any,
    { dispatch }
  ) => {
    const response = isUpdate
      ? await OrderProductAPI.update(orderProductId, orderParams)
      : await OrderProductAPI.create(
          config.productId,
          orderParams,
          configIndex // include config index to ensure unique idempotency keys for products with quantity > 1.
        );

    const productInstanceId = _.get(response, "data.id");
    if (productInstanceId) {
      await Promise.all(
        config.userDocuments.map((doc: any, docIndex: number) => {
          return dispatch(
            uploadDocument({
              productInstanceId,
              configIndex,
              config,
              docIndex,
              doc,
            })
          ).unwrap();
        })
      );
    } else {
      console.log("No product instance ID returned to upload docs against");
    }

    return response;
  }
);

export const uploadDocument = createAsyncThunk(
  "universalProducts/uploadDocument",
  async (
    { productInstanceId, configIndex, config, docIndex, doc }: any,
    { getState }
  ) => {
    return await OrderProductAPI.uploadDocument(
      productInstanceId,
      doc,
      config.userDocumentsSendPref[docIndex]
    );
  }
);

export const orderUniversalProducts = createAsyncThunk(
  "universalProducts/orderUniversalProducts",
  async (arg, { getState, dispatch }) => {
    const state: any = getState();
    const { configs } = state.universalProducts;
    return await Promise.all(
      configs.map(async (config: any, configIndex: number) => {
        const orderProductId = _.get(config, "orderProduct.response.data.id");
        return dispatch(
          orderProduct({
            configIndex,
            config,
            orderProductId,
            isUpdate: !!orderProductId,
            orderParams: {
              contract_length_in_months: state.order.contractLength,
              account_id: state.order.accountId,
              order_id: state.order.id,
              "universal-recurring_discount_override": 1,
              "universal-recurring_discount_type": "SpecifyPrice",
              "universal-recurring_discount_value": config.recurringPrice,
              "universal-one_off_discount_override": 1,
              "universal-one_off_discount_type": "SpecifyPrice",
              "universal-one_off_discount_value": config.oneOffPrice,
            },
          })
        ).unwrap();
      })
    );
  }
);

export const setProductDiscount = createAction(
  "universalProducts/setProductDiscount",
  (
    targetConfigs: any,
    productType: string,
    priceType: string | null,
    discountType: string | null,
    value: any,
    fullPrice: any
  ) => ({
    payload: {
      targetConfigs,
      productType,
      priceType,
      discountType,
      value,
      fullPrice,
    },
  })
);

export const setPricingScheme = createAction(
  "universalProducts/setPricingScheme",
  (selectedConfigIndex: number, scheme: string) => ({
    payload: { scheme, selectedConfigIndex },
  })
);
